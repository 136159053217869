import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill';

import App from './App';
import { queryClient } from './services/queryClient';

import { icons } from './assets/icons';
import { Provider } from 'react-redux';
import store from './store';

React.icons = icons

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);
